<template>
  <div class="container">
    <PublicHeader
      :totalName="$t('rechargeService').InpourInformation"
      :routerName="routerName"
    />
    <div class="title up">{{ $t('rechargeService').InpourInformation }}</div>
    <div class="line">
      <span>{{ $t('rechargeService').Amount }}</span>
      <span>{{ Amount }}</span>
    </div>
    <div class="line">
      <span>{{ $t('rechargeService').CardNo }}</span>
      <span style="color: #999">{{ $t('rechargeService').placeholder }}</span>
    </div>
    <div class="bigBtn" @click="handleHelp">
      {{ $t('rechargeService').Support }}
    </div>
    <div class="title up">{{ $t('rechargeService').Warm }}</div>
    <p>{{ $t('rechargeService').line }}</p>
    <p>{{ $t('rechargeService').line1 }}</p>
    <p>{{ $t('rechargeService').line2 }}</p>
    <p>{{ $t('rechargeService').line3 }}</p>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import { mapGetters } from 'vuex'
export default {
  components: {
    PublicHeader,
  },
  data() {
    return {
      routerName: 'Me',
      Amount: 0,
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
  },
  methods: {
    handleHelp() {
      window.location.assign(this.basicConfig.ServiceUrl)
    },
  },
  mounted() {
    this.Amount = this.$route.params.Amount
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.container
  width: 100%;
  background-color #1f1f1f
  font-size 12px
  color #ebe5cd
  overflow-x hidden
  height 100vh
  padding 48.5px 14px 0
  .line
      height 40px
      width 100%
      flex-between()
      padding 0 10px
      border-bottom 1Px solid #40404b
      span:nth-of-type(2)
        color #fff
  .title
    margin 13px 0
  p
    margin 5px
    line-height 1.5
  .bigBtn
    width: 100%
    height 40px
    flex-center()
    color #fff
    font-size: 15px
    background: linear-gradient(315deg,#e8d49e,#d6b372)!important;
    margin 25px auto
    border-radius: 5px
</style>
